<template>
    <div class="container-fluid">
        <b-row>
            <div class="col-lg-4">
                <div class="card card-transparent card-block card-stretch card-height border-none">
                    <div class="card-body p-0 mt-lg-2 mt-0">
                        <h3 class="mb-3">Order {{ orderNo }}</h3>
                        <p class="mb-0 mr-4">Order placed on {{ orderDate }}</p>
                        <order-status class="mt-4" :status="orderInfo.status" />
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-lg-4 col-md-4" v-for="(progres, index) in progress" :key="index">
                        <div class="card card-block card-stretch card-height">
                            <div class="card-body">
                                <div class="d-flex align-items-center mb-1 card-total-sale">
                                    <div>
                                        <p class="mb-2">{{ progres.title }}</p>
                                        <h4>{{ progres.contain }}</h4>
                                    </div>
                                </div>
                                <!-- <div class="iq-progress-bar mt-2">
                                    <Progressbar :color="progres.color" :value="progres.value" />
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-row>
        <b-row>
            <b-col lg="12" class="justify-content-end d-flex mb-5">
                <b-button v-if="!hasInvoice && orderInfo.status !== 'canceled'" v-b-modal.invoice-order__checkoutcom variant="primary" class="mt-2 mr-1">Accept & Invoice</b-button>
                <b-button  v-if="availableShip && hasInvoice && orderInfo.status !== 'canceled'" v-b-modal.create_fulfilment variant="primary" class="mt-2 mr-1">Create Fulfilment</b-button>
                <!-- This section is to cancel the order if the state is 'new'-->
                <b-button v-if="!hasInvoice && orderInfo.status !== 'canceled'" v-b-modal.cancel-order variant="secondary" class="mt-2 mr-1">Cancel</b-button>
                <cancelOrder />
                <createInvoice />
                <createFulfilment />
                </b-col>
                </b-row>
                <b-row>
            <b-col lg="12">
                <div class="table-responsive-sm">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Contact Information</th>
                                <th scope="col">Billing Address</th>
                                <th scope="col">Shipping Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Name: {{ orderInfo.customer_firstname }} {{ orderInfo.customer_lastname }}<br>
                                    Email: {{ orderInfo.customer_email }}<br>
                                    Type: Guest
                                </td>
                                <td>
                                    <p class="mb-0">{{ orderBillingAddress.street[0] }}
                                        {{ orderBillingAddress.street[1] }}<br>
                                        {{ orderBillingAddress.city }}<br>
                                        {{ orderBillingAddress.postcode }}<br>
                                        {{ orderBillingAddress.country_id }}<br>
                                        Telephone: {{ orderBillingAddress.telephone }}
                                    </p>
                                </td>
                                <td>
                                    <p class="mb-0">{{orderShippingAddress.street[0]}} 
                                        {{ orderShippingAddress.street[1] }}<br>
                                        {{ orderShippingAddress.city }}<br>
                                        {{ orderShippingAddress.postcode }}<br>
                                        {{ orderShippingAddress.country_id }}<br>
                                        Telephone: {{ orderShippingAddress.telephone }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <h5 class="mb-3">Ordered Items</h5>
                <div class="table-responsive-sm">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Item</th>
                                <th class="text-center" scope="col">Quantity</th>
                                <th class="text-center" scope="col">Subtotal</th>
                                <th class="text-center" scope="col">Discount</th>
                                <th class="text-center" scope="col">Tax / VAT</th>
                                <th class="text-center" scope="col">Totals</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in orderItems" :key="index">
                                <td>
                                    <h6 class="mb-0">{{ item.name }}</h6>
                                    <p class="mb-0">{{ item.sku }}
                                    </p>
                                </td>
                                <td class="text-center">{{ item.qty_ordered }}</td>
                                <td class="text-center">{{ orderInfo.order_currency_code }} {{ item.row_total }}</td>
                                <td class="text-center">{{ orderInfo.order_currency_code }} {{ item.discount_amount }}</td>
                                <td class="text-center">{{ orderInfo.order_currency_code }} {{ item.tax_amount }} ({{item.tax_percent}}%)</td>
                                <td class="text-center"><b>{{ orderInfo.order_currency_code }} {{ item.row_total_incl_tax }}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
        <b-row class=" mt-4 mb-3">
            <div class="offset-lg-8 col-lg-4">
                <div class="or-detail rounded">
                    <div class="p-3">
                        <h5 class="mb-3">Order Total</h5>
                        <div class="mb-2 d-flex justify-content-between align-items-center">
                            <h6>Subtotal</h6>
                            <p>{{ orderInfo.base_currency_code }} {{orderInfo.subtotal}}</p>
                        </div>
                        <div class="mb-2 d-flex justify-content-between align-items-center">
                            <h6>Shipping & Handling</h6>
                            <p>{{ orderInfo.base_currency_code }} {{orderInfo.shipping_amount}}</p>
                        </div>
                        <div class="mb-2 d-flex justify-content-between align-items-center">
                            <h6>Tax / VAT</h6>
                            <p>{{ orderInfo.base_currency_code }} {{orderInfo.tax_amount}}</p>
                        </div>
                        <div class="mb-2 d-flex justify-content-between align-items-center">
                            <h6>Discount</h6>
                            <p>{{ orderInfo.base_currency_code }} {{orderInfo.base_discount_amount}}</p>
                        </div>
                    </div>
                    <div class="ttl-amt py-2 px-3 d-flex justify-content-between align-items-center">
                        <h6>Total</h6>
                        <h3 class="text-primary font-weight-700">{{ orderInfo.base_currency_code }} {{parseFloat(orderInfo.base_grand_total).toFixed(2) }}</h3>
                    </div>
                </div>
            </div>
        </b-row>
    </div>
</template>
<script>
// import { DataService } from '../../../config/dataService'
import cancelOrder from '../../../components/omni/orders/modals/cancel-order'
import createInvoice from '../../../components/omni/orders/modals/invoice-order-checkoutcom'
import createFulfilment from '../../../components/omni/orders/modals/create-fulfilment.vue'
import orderStatus from '../../../components/omni/orders/modals/status.vue'
var CryptoJS = require("crypto-js");
export default {
    name: 'OrderView',
    components: {
        cancelOrder,
        createInvoice,
        createFulfilment,
        orderStatus
    },
    computed: {
        ammountAuth() {
            return this.$store.state.orders.thirdPartyPaymentInfo?.balances?.total_authorized * 0.01
        },
        ammountCaptured() {
            return this.$store.state.orders.thirdPartyPaymentInfo?.balances?.total_captured * 0.01
        },
        paymentStatus() {
            return this.$store.state.orders.thirdPartyPaymentInfo?.status
        },
        id() {
            return this.$route.params.id
        },
        orderBillingAddress() {
            return this.$store.state.orders.order.billing_address
        },
        orderShippingAddress() {
            return this.$store.state.orders.order.extension_attributes.shipping_assignments[0].shipping.address
        },
        orderItems() {
            return this.$store.state.orders.order.items
        },
        orderInfo() {
            return this.$store.state.orders.order
        },
        orderNo() {
            return this.$store.state.orders.order.increment_id
        },
        orderDate() {
            return this.$store.state.orders.order.created_at
        },
        paymentId() {
            return this.$store.state.orders.order.extension_attributes.payment_additional_info.find(
                (info) => info.key === 'paymentID',
            );
        },
        qtyInvoiced: function () {
            return this.$store.state.orders.order.items.reduce(function (total, item) {

                return total + item.qty_invoiced;
            }, 0);
        },
        hasInvoice() {
            if (this.qtyInvoiced > 0){
                return true
            } else {
                return false
            }
        },
        availableShip() {
            if (this.qtyShipped !== this.qtyOrdered){
                return true
            } else {
                return false
            }
        },
        qtyOrdered: function () {
            return this.$store.state.orders.order.items.reduce(function (total, item) {

                return total + item.qty_ordered;
            }, 0);
        },
        qtyShipped: function () {
            return this.$store.state.orders.order.items.reduce(function (total, item) {

                return total + item.qty_shipped;
            }, 0);
        },
        progress() {
            return [
                {
                    color: 'info',
                    src: require('@/assets/images/product/1.png'),
                    title: 'Invoiced',
                    contain: this.qtyInvoiced + ' of ' + this.qtyOrdered,
                    value: 100
                },
                {
                    color: 'danger',
                    src: require('@/assets/images/product/2.png'),
                    title: 'Fulfilled',
                    contain: this.qtyShipped + ' of ' + this.qtyOrdered,
                    value: 0
                },
                {
                    color: 'success',
                    src: require('@/assets/images/product/3.png'),
                    title: 'Payment Status',
                    contain: 'Data Unavailable',
                    value: (this.ammountAuth / this.ammountCaptured) * 100
                }
            ]
        }
    },
    methods: {
        // async getStripe() {
        //     if (this.paymentId) {
        //         const {
        //             data: { data, status },
        //         } = await DataService.post(`bmw/stripe/transaction`, {
        //             payment_id: this.paymentId.value,
        //         });

        //         if (status === 'success') {
        //             this.$store.commit('setThirdPartyPaymentInfo', data);
        //             // paymentData.value = data && data['@attributes'];
        //         } else {
        //             this.$store.commit('setThirdPartyPaymentInfo', false);
        //         }
        //     }
        // },
        // async checkoutcomv2() {
        //         const {
        //             data: { data, status },
        //         } = await DataService.get(`dubai/checkoutcomv2/getPaymentDetails/pay_wbisiagvqe6ubmsxlvlggtlcta`);

        //         if (status === 'success') {
        //             this.$store.commit('setThirdPartyPaymentInfo', data);
        //             // paymentData.value = data && data['@attributes'];
        //         } else {
        //             this.$store.commit('setThirdPartyPaymentInfo', false);
        //         }
        // },
    },
    beforeMount() {
        let encrypted = CryptoJS.SHA256(this.$route.params.number + this.$route.params.id, "OmniAutomotive@777").toString(CryptoJS.enc.Hex);
    if (encrypted !== this.$route.params.encryptkey) {
    this.$router.push({ path: '/orders' });
    } else {
        this.$store.dispatch('fetchOrder', {
            entityId: this.$route.params.id
        });
        this.checkoutcomv2()
    }
    },
    beforeDestroy() {
        this.$store.commit('fetchOrderSuccess', {
            data: ''
        });
        this.$store.commit('setThirdPartyPaymentInfo', '');
    }
}
</script>