<template>
    <div class="modal">
        <b-modal id="cancel-order" centered hide-footer hide-header hide-header-close>
            <div class="overlay" v-show="isLoading">
    <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
    </div>
</div>
            <div class="popup text-left">
                <h4 class="mb-3">Cancel Order</h4>
                <div class="content create-workform bg-body">
                    <b-alert :show="true" variant="danger">
  <div class="iq-alert-icon">
    <i class="ri-information-line"></i>
  </div>
  <div class="iq-alert-text">You are cancelling order #{{orderInfo.increment_id}} , this action is not reversable. This action will not perform an automatic refund. Are you sure you want to continue?</div>
</b-alert>
                    <div class="col-lg-12 mt-4">
                        <div class="d-flex flex-wrap align-items-ceter justify-content-center">
                            <div class="btn btn-secondary mr-4" data-dismiss="modal" @click="$bvModal.hide('cancel-order')">
                                Don't cancel</div>
                            <div class="btn btn-primary" data-dismiss="modal"
                                @click="cancelOrder()">Cancel Order</div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div> 
</template>
<script>
export default {
    name: "cancelOrder",
computed: {
    isLoading() {
            return this.$store.state.orders.saving
        },
    orderInfo() {
            return this.$store.state.orders.order
        },
    },
    methods: {
        cancelOrder(){
        this.$store.dispatch('cancelOrder', {
            entityId: this.$route.params.id
        });
        }
    }
}
</script>
<style lang="scss">
.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    background: rgba(21,26,48,0.3);
    backdrop-filter: blur(5px);
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>