<template>
    <div class="status" :class="status">
        <div class="icon">
            <svg v-if="status === 'pending'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64" height="64"
                viewBox="0 0 72 72" style="fill:#0066FF;">
                <path
                    d="M36,62c-14.337,0-26-11.664-26-26s11.663-26,26-26s26,11.664,26,26S50.337,62,36,62z M36,16	c-11.028,0-20,8.972-20,20s8.972,20,20,20s20-8.972,20-20S47.028,16,36,16z">
                </path>
            </svg>
            <svg v-else-if="status === 'processing'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64"
                height="64" viewBox="0 0 64 64">
                <path
                    d="M 32 6 C 17.664 6 6 17.664 6 32 C 6 46.336 17.664 58 32 58 C 46.336 58 58 46.336 58 32 C 58 17.664 46.336 6 32 6 z M 32 9.7148438 L 32 32 L 32 54.285156 C 19.711 54.285156 9.7148438 44.289 9.7148438 32 C 9.7148438 19.711 19.711 9.7148438 32 9.7148438 z">
                </path>
            </svg>
            <svg v-else-if="status === 'complete'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48"
                height="48" viewBox="0 0 24 24">
                <path
                    d="M12,2C6.477,2,2,6.477,2,12c0,5.523,4.477,10,10,10s10-4.477,10-10C22,6.477,17.523,2,12,2z M10,17.414l-4.707-4.707 l1.414-1.414L10,14.586l7.293-7.293l1.414,1.414L10,17.414z">
                </path>
            </svg>
            <svg v-else-if="status === 'canceled'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50"
                height="50" viewBox="0 0 50 50">
                <path
                    d="M 45.960938 1.9804688 A 2.0002 2.0002 0 0 0 44.585938 2.5859375 L 39.783203 7.3886719 C 35.784866 4.0305094 30.620477 2 25 2 C 12.321124 2 2 12.321124 2 25 C 2 30.620022 4.0284529 35.786167 7.3867188 39.785156 L 2.5859375 44.585938 A 2.0002 2.0002 0 1 0 5.4140625 47.414062 L 47.414062 5.4140625 A 2.0002 2.0002 0 0 0 45.960938 1.9804688 z M 25 6 C 29.537325 6 33.684636 7.5809169 36.947266 10.224609 L 10.224609 36.947266 C 7.5806394 33.684274 6 29.537298 6 25 C 6 14.482876 14.482876 6 25 6 z M 43.845703 11.8125 L 40.955078 14.699219 C 42.928078 17.741219 44 21.281 44 25 C 44 35.517124 35.517124 44 25 44 C 21.292 44 17.741312 42.936891 14.695312 40.962891 L 11.826172 43.830078 C 15.666172 46.525078 20.221 48 25 48 C 37.678876 48 48 37.678876 48 25 C 48 20.204 46.543703 15.6475 43.845703 11.8125 z">
                </path>
            </svg>
        </div>
        <span>{{ status }}</span>
    </div>
</template>
<script>
export default {
    name: "Status",
    props: {
        status: { type: String, default: "" }
    },
    computed: {
    }
}
</script>
<style scoped lang="scss">
.pending {
    background: #CFE0FC;
    color: #0066FF;

    .icon {
        svg {
            fill: #0066FF !important;
        }
    }
}

.complete {
    background: #EBF6EC;
    color: #0ABF53;

    .icon {
        svg {
            fill: #0ABF53 !important;
        }
    }
}

.canceled {
    background: #F3CECD;
    color: #A10000;

    .icon {
        svg {
            fill: #A10000 !important;
        }
    }
}

.processing {
    background: #FCEACF;
    color: #FF9500;

    .icon {
        svg {
            fill: #FF9500 !important;
        }
    }
}

.status {
    display: flex;
    text-transform: capitalize;
    padding: 5px 15px;
    font-weight: 700;
    font-size: 12px;
    min-width: 120px;
    max-width: 120px;
    border-radius: 8px;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;

        svg {
            width: 25px;
            height: 25px;
        }
    }
}
</style>