<template>
    <div class="modal">
        <b-modal id="invoice-order__checkoutcom" centered hide-footer size="lg" scrollable title="Invoice & Accept">
            <div class="overlay" v-show="isLoading">
    <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
    </div>
</div>
            <div class="popup text-left">
                <div class="content create-workform bg-body">
                    <b-alert :show="true" variant="secondary" class="bg-white">
                        <div class="iq-alert-icon">
                            <i class="ri-information-line"></i>
                        </div>
                        <div class="iq-alert-text">You can only create <b>one</b> invoice for this order. Select all of
                            the items you wish to process below.</div>
                    </b-alert>
                    <div class="table-responsive-sm">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Item</th>
                                    <th class="text-center" scope="col">Quantity</th>
                                    <th class="text-center" scope="col">Line Total<small> Inc VAT</small></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in orderInfo" :key="index">
                                    <td>
                                        <h6 class="mb-0">{{ item.name }}</h6>
                                        <p class="mb-0">{{ item.sku }}
                                        </p>
                                    </td>
                                    <td class="text-center">
                                        <select name="" id="input" class="form-control" v-model="item.qty_to_invoice">
                                            <option :value="0">None</option>
                                            <option v-for="n in item.qty_ordered" :key="n" :value="(n)">{{ n }} of {{
                                                    item.qty_ordered
                                            }}</option>
                                        </select>
                                    </td>
                                    <td class="text-center">AED {{ parseFloat((item.qty_to_invoice * item.price_incl_tax) ).toFixed(2) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-lg-12 mt-4">
                        <div class="or-detail rounded" style="background: #f4f4f4">
                            <div class="p-3">
                                <h5 class="mb-3">Order Total</h5>
                                <div class="mb-2 d-flex justify-content-between align-items-center">
                                    <h6>Items Total <small>(Inc VAT)</small></h6>
                                    <p>AED {{parseFloat(totalItem).toFixed(2) }}</p>
                                </div>
                                <div class="mb-2 d-flex justify-content-between align-items-center">
                                    <h6>Shipping & Handling <small>(Inc VAT)</small></h6>
                                    <p>AED {{parseFloat(orderShipping).toFixed(2) }}</p>
                                </div>
                            </div>
                            <div class="ttl-amt py-2 px-3 d-flex justify-content-between align-items-center">
                                <h6>Total</h6>
                                <h5 class="text-primary font-weight-700">AED {{parseFloat((totalItem + orderShipping) ).toFixed(2) }}</h5>
                            </div>
                        </div>
                        <div class="d-flex mt-4 flex-wrap align-items-ceter justify-content-end">
                            <div class="btn btn-primary mr-4" data-dismiss="modal" @click="createInvoiceOnly()">
                                Create Invoice</div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    name: "cancelOrder",
    computed: {
        isLoading() {
            return this.$store.state.orders.saving
        },
        orderInfo() {
            return this.$store.state.invoices.newInvoice
        },
        orderShipping() {
            return this.$store.state.orders.order.shipping_incl_tax
        },
        totalItem: function () {
            let sum = 0;
            for (let i = 0; i < this.orderInfo.length; i++) {
                sum += (parseFloat(this.orderInfo[i].price_incl_tax) * parseFloat(this.orderInfo[i].qty_to_invoice));
            }

            return sum;
        }
    },
    methods: {
        getLineTotal(item) {
            return item.qty_to_invoice * item.price
        },
        calculateTotals() {
            const orderInfo = this.orderInfo
            return orderInfo.reduce((n, { price }) => n + price, 0)
        },
        async createInvoiceOnly() {
            // const shouldCreateShipment = this.$refs['paymentMethod'].createShipment;
            let items = this.orderInfo.map(function (item) {
                return {
                    order_item_id: item.item_id,
                    qty: item.qty_to_invoice,
                };
            })
            const payload = {
                entity: {
                    items,
                    notify: true,
                    appendComment: true,
                    capture: true,
                },
            };
            await this.$store.dispatch('createInvoiceOnly', { payload, orderId: this.$route.params.id });
        },
    },
    beforeMount() {
    },
    beforeDestroy() {
        this.$store.commit('invoices/setAvailableItems', {
            data: ''
        });
    }
}
</script>
<style lang="scss">
.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    background: rgba(21,26,48,0.3);
    backdrop-filter: blur(5px);
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>